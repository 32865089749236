@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #0066FF;
  --primary-bright: #0066FF;
  --primary-bright-light: rgba(0, 102, 255, 0.15);
  --secondary: #25245C;
  --passive: #efefef;
  --passive-2: #888;
  --passive-3: rgba(29, 38, 144, 0.2);
  --passive-4: rgba(0, 102, 255, 0.7);
  --warn: #F0B90B;
  --warn-light: #FEF6D8;
  --info: rgba(0, 102, 255, 0.5);
  --info-light: rgba(0, 102, 255, 0.05);
  --error: #E14A57;
  --error-light: rgba(225, 74, 87, 0.1);
}

@layer base {
  body {
    @apply bg-passive;
    font-family: 'Inter', serif;
  }
}
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .select-wrapper {
    position: relative;
  }

  .option-btn {
    @apply border border-passive-3 focus:ring-blue-200 focus:border-white focus:outline-none focus:ring ring-opacity-75;
    cursor: pointer;
    border-radius: 0.5em;
    padding: 0.75em 1.25em;
    font-size: 1em;
    color: var(--secondary);
  }

  .option-btn:hover {
    @apply border-secondary;
  }

  .option-btn.selected {
    background: var(--primary-bright-light);
    box-shadow: 0 0 2px 2px var(--primary-bright-light);
    font-weight: bold;
  }


  .option-btn2 {
    padding: 1em;
    border: 1px solid var(--passive-3);
    display: flex;
    border-radius: 0.25em;
  }

  .option-btn2:hover {
    border-color: var(--primary-bright)
  }

  .option-btn2.selected {
    background: var(--info-light);
  }

  .select-wrapper::after {
    content: "";
    position: absolute;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 13px;
    height: 8px;
    background-image: url("/public/assets/img/chevron-down.svg");
    top: 50%;
    transform: translateY(-50%);
  }

  input[type=text] {
    @apply  border-0 focus-within:outline-none focus-within:ring-0;
  }

  input[type=text].input {
    @apply border border-passive-3 focus-within:ring-blue-200  focus-within:outline-none focus-within:ring ring-opacity-75;
  }

  .input {
    @apply border border-passive-3 focus-within:ring-blue-200 focus-within:outline-none focus-within:ring ring-opacity-75;
    padding: 16px 12px;
    font-size: 16px;
    width: 100%;
    background: white;
    border-radius: 4px;
  }

  .input.mark-input-error {
    box-shadow: 0 0 3 3px var(--passive-4)
  }

  .input::placeholder {
    font-size: 16px;
    color: #C8C8D6;
  }

  .input-content{
    @apply flex items-center  rounded;
    @apply border border-passive-3 focus-within:ring-blue-200 focus-within:border-white focus-within:outline-none focus-within:ring ring-opacity-75;
  }

  .input-content .input-prefix {
    @apply pl-2;
  }

  .input-content input {
    @apply w-full h-full py-4 pr-3;
    font-size: 16px;
    width: 100%;
    background: white;
    border-radius: 4px;
  }

  .h1 {
    color: var(--primary);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  main>section {
    @apply  xs:mx-4;
  }
  .form-container {
    @apply w-[480px] xs:w-full p-12 mt-6 bg-white self-center xs:p-8;
  }

  .button1 {
    @apply disabled:bg-gray-500 disabled:pointer-events-none;

    width: 100%;
    padding: 16px;
    background: var(--primary);
    color: white;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
  }

  .button1.outlined {
    @apply bg-transparent text-primary w-full border border-passive-3;
  }

  .button2 {
    width: 100%;
    padding: 12px;
    background: white;
    font-size: 16px;
    text-align: right;
    margin-top: 12px;
  }

  .button-light {
    padding: 1em 2em;
    border: 1px solid var(--passive-3);
    border-radius: 0.25em;
    text-align: center;
    width: fit-content;
    margin: auto;
    cursor: pointer;
  }

  .button-light:hover {
    border-color: var(--primary);
  }

  .button1.disabled {
    background: var(--passive);
    cursor: default;
  }

  .footer {
    bottom: 0;
    text-align: center;
    padding: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .infinite-spin {
    animation: rotation 2.4s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }



.capitalize {
  text-transform: capitalize;
}
.logo-section{
  max-width: 250px;
  margin:0 auto;
}



@layer components {
  .text {
    @apply text-gray-800 text-base font-normal;
  }
  .form-label {
    @apply text-sm text-gray-800 font-normal;
  }

  .alert {
    @apply p-4 flex items-center gap-2;
  }
  .alert.warning {
    @apply bg-warn/20 text-secondary rounded;
  }
}



/*DATEPICKER CSS*/
.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input input mb-4 py-16 px-8
}

.react-datepicker-popper {
  @apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded
}

.react-datepicker__month-container {
  @apply flex flex-col
}

.react-datepicker__month {
  @apply flex flex-col
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800
}

.react-datepicker__week {
  @apply flex justify-around
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
  @apply absolute top-2
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded hover:bg-blue-500 cursor-pointer hover:bg-opacity-40 hover:text-gray-900
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent
}

.react-datepicker__day--outside-month {
  @apply text-gray-300
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white
}
/*///DATEPICKER CSS*/


.swal2-styled.swal2-confirm {
  background-color: var(--primary) !important;
}

